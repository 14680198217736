import * as S from './styles/FooterStyles'
import {Link} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {useLocation} from "react-router";

function Footer(props) {

    const isMobile = useMediaQuery({query : '(min-width:0) and (max-width:480px)'})
    const location = useLocation()


    // 푸터 안 보여야하는 페이지 주소 배열
    const hidePCFooterUrl = ['/insurancedetail' , '/fiercedoginsurance' , '/petinsurance' , '/signup' , '/login' , '/findid'
        , '/findpassword' , '/claiminsurance' , '/becomerecommender' , '/applyforrecommender'
        , '/communitywriting' , '/comment' , '/inquiry' , '/supportprediction'
        , '/renewinsurance','/community'];

    const hideMobileFooterUrl = ['/insurancedetail' , '/fiercedoginsurance' , '/petinsurance' , '/signup' , '/login' , '/findid'
        , '/findpassword' , '/claiminsurance' , '/whatisrecommender' , '/becomerecommender' , '/applyforrecommender'
        , '/communitywriting' , '/comment' , '/inquiry' , '/newbreeddiscriminator', '/supportprediction'
        , '/lookalikediscriminator' , '/renewinsurance','/community'];




    // path 파라미터 제거하기 위한 함수
    function getSubstringBeforeSecondSlash(pathname) {

        if(pathname !== '/'){
            // 문자열에서 첫 번째 /의 인덱스를 찾습니다.
            let firstSlashIndex = pathname.indexOf('/');

            if (firstSlashIndex !== -1) {
                // 첫 번째 /가 발견되었을 때, 두 번째 /의 인덱스를 찾습니다.
                let secondSlashIndex = pathname.indexOf('/', firstSlashIndex + 1);

                if (secondSlashIndex !== -1) {
                    // 두 번째 / 이전의 부분 문자열을 반환합니다.
                    return pathname?.slice(0, secondSlashIndex);
                }
            }
            // 두 번째 /가 없거나 문자열이 비어있는 경우에는 기존 주소를 반환합니다.
            return pathname;
        }

    }

    return (
        <>
            {
                isMobile
                ?
                    <S.Wrapper display={hideMobileFooterUrl.includes(getSubstringBeforeSecondSlash(location.pathname)) || location.pathname === '/mypage/mypet'}
                    padding={location.pathname === '/'}>
                        <S.Container>
                            <S.Top>
                                <p>(주)이디피랩</p>
                                <p>
                                    대표자: 심준원 | 사업자등록번호 645-87-01574 | 통신판매업 신고: 제2020-서울마포-2932호 | (03187)<br/>
                                    서울특별시 종로구 종로 6 서울창조경제혁신센터 (서린동, 광화문우체국) 5층 501호, 508호<br/>
                                    copyright (C) 2019 by petfins All rights reserved
                                </p>
                                <ul>
                                    <li>
                                        <Link to={'/privacypolicy'}>개인정보처리방침</Link>
                                    </li>
                                    <S.ColumnLineIcon aria-hidden={true}/>
                                    <li>
                                        <Link to={'/termsofservice'}>서비스 이용약관</Link>
                                    </li>
                                </ul>
                            </S.Top>
                            <S.Bottom>
                                <div>
                                    <p>고객센터</p>
                                    <p>
                                        02-2038-0828 (ARS 1번) | 평일 09:00 - 18:00 (공휴일 휴무) <br/>
                                        E-mail : petfins@edplab.kr
                                    </p>
                                    <S.Third>
                                        <p>삼성화재 준법감시인확인필 제22-184호</p>
                                        <p>현대해상 준법감시인확인필 20217361호</p>
                                        <p>하나은행 준법감시인심의필 제2021-광고-6757호</p>
                                        <p>생명보험협회 심의필 제2021-01650호</p>
                                    </S.Third>

                                    <ul>
                                        <li>
                                            <Link to={'https://www.facebook.com/people/%ED%8E%AB%ED%95%80%EC%8A%A4/100063766711376'}
                                            target={'_blank'}
                                            title={'펫핀스 페이스북 페이지로 이동'}
                                            >
                                                <S.FacebookIcon aria-hidden={true} />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'https://www.instagram.com/petfins'} target={'_blank'}
                                                  title={'펫핀스 인스타그램 페이지로 이동'}
                                            >
                                                <S.InstagramIcon aria-hidden={true} />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'https://blog.naver.com/petfins'} target={'_blank'}
                                                  title={'펫핀스 블로그 페이지로 이동'}
                                            >
                                                <S.BlogIcon aria-hidden={true} />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                            </S.Bottom>

                        </S.Container>
                    </S.Wrapper>
                    :
                    <S.Wrapper display={hidePCFooterUrl.includes(getSubstringBeforeSecondSlash(location.pathname))}>
                        <S.Container>
                            <S.First>
                                <p>(주)이디피랩</p>
                                <p>
                                    대표자: 심준원 | 사업자등록번호 645-87-01574 | 통신판매업 신고: 제2020-서울마포-2932호 | (03187)<br/>
                                    서울특별시 종로구 종로 6 서울창조경제혁신센터 (서린동, 광화문우체국) 5층 501호, 508호<br/>
                                    copyright (C) 2019 by petfins All rights reserved
                                </p>
                                <ul>
                                    <li>
                                        <Link to={'/privacypolicy'}>개인정보처리방침</Link>
                                    </li>
                                    <S.ColumnLineIcon aria-hidden={true} />
                                    <li>
                                        <Link to={'/termsofservice'}>서비스 이용약관</Link>
                                    </li>
                                </ul>
                            </S.First>
                            <S.Second>
                                <p>고객센터</p>
                                <p>
                                    02-2038-0828 (ARS 1번) <br/>
                                    평일 09:00 - 18:00 (공휴일 휴무) <br/>
                                    E-mail : petfins@edplab.kr
                                </p>
                                <ul>
                                    <li>
                                        <Link to={'https://www.facebook.com/people/%ED%8E%AB%ED%95%80%EC%8A%A4/100063766711376'} target={'_blank'}
                                              title={'펫핀스 페이스북 페이지로 이동'}

                                        >
                                            <S.FacebookIcon aria-hidden={true} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'https://www.instagram.com/petfins'} target={'_blank'}
                                              title={'펫핀스 인스타그램 페이지로 이동'}

                                        >
                                            <S.InstagramIcon aria-hidden={true} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'https://blog.naver.com/petfins'} target={'_blank'}
                                              title={'펫핀스 블로그 페이지로 이동'}
                                        >
                                            <S.BlogIcon aria-hidden={true} />
                                        </Link>
                                    </li>
                                </ul>
                            </S.Second>
                            <S.Third>
                                <p>삼성화재 준법감시인확인필 제22-184호</p>
                                <p>현대해상 준법감시인확인필 20217361호</p>
                                <p>하나은행 준법감시인심의필 제2021-광고-6757호</p>
                                <p>생명보험협회 심의필 제2021-01650호</p>
                            </S.Third>

                        </S.Container>
                    </S.Wrapper>
            }
        </>

    );
}

export default Footer;