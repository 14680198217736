import {css} from 'styled-components';

const theme = {
    colors: {
        main: '#FF4E00',
        gray: '#F7F7F7',
        gray2: '#EAEAEA',
        gray3: '#D0D0D0',
        gray4: '#ACACAC',
        gray5: '#8B8B8B',
        gray6: '#454545',
        gray7: '#313131',
        customBlack: '#101010',
        background: '#F9F9F9',
        blue:'#0068E2',
        red:'#F00'

    },
    button: {
        active1: css`
          background-color: #FF4E00;
          border-radius: 12px;
          color: #FFFFFF;
          font-weight: 500;
        `,
        active2: css`
          background: #FFFFFF;
          border-radius: 12px;
          border: #FF4E00 1px solid;
          color: #FF4E00;
          font-weight: 500;
        `,
        disabled1: css`
          background-color: #ACACAC;
          border-radius: 12px;
          color: #FFFFFF;
          font-weight: 500;
        `,
        disabled2: css`
          background: #FFFFFF;
          border-radius: 12px;
          font-weight: 500;
          border: #ACACAC 1px solid;
          color: #ACACAC;

        `,
        confirm: css`
          background: #FFFFFF;
          border-radius: 12px;
          font-weight: 500;
          border: #101010 1px solid;
          color: #101010;
        `,
        file: css`
          background: #FFFFFF;
          border-radius: 12px;
          font-weight: 500;
          border: #0068E2 1px solid;
          color: #0068E2;
        `
    },
    input: {
        default: css`
          border: #EAEAEA 1px solid;
          background: #FFFFFF;
          border-radius: 12px;
          
          &::placeholder {
            color: #8B8B8B;
          }
        ;
        `,
        active: css`
          border-radius: 12px;
          border: #8B8B8B 1px solid;
          background: #FFFFFF;
          color: #101010;

          &::placeholder {
            color: #101010;
          }
        ;
        `,
        disabled: css`
          border-radius: 12px;
          background: #D0D0D0;
          border:none;

          &::placeholder {
            color: rgb(139, 139, 139);
          }
        ;
        `,
        error: css`
          border: #FF0000 1px solid;
          color: #101010;

          &::placeholder {
            color: #101010;
          }
        ;
        `
    },
    font:{
      mainDate: css`
      font-size:1.6rem;
        line-height: 2rem;
      `,
        mainText: css`
      font-size:1.6rem;
        line-height: 2.2rem;
      `,
        mainTitle: css`
      font-size:1.8rem;
        line-height: 2.4rem;
      `,
        error: css`
      font-size:1.4rem;
        line-height: 1.8rem;
      `,
        contentTitle: css`
      font-size:1.6rem;
        line-height: 1.8rem;
      `,
        pageTitle: css`
      font-size:2.6rem;
        line-height: 3rem;
      `,
        listTitle: css`
      font-size:1.8rem;
        line-height: 2.4rem;
      `,
    },
    allOfCenter: css`
    display: flex;
      justify-content: center;
      align-items: center;
`
};


export default theme;