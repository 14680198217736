import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";

export const useSetTitle = (name,deps) => {

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(()=>{
        document.title = name ;
    }, [searchParams,deps])
}