import {useEffect} from 'react';
import styled from "styled-components";


function Modal({children,handleModal}) {

    useEffect(() => {
        document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        };
    }, []);



    return (
        <>

            <Wrapper onClick={() => {
                    handleModal();
            }}/>
            <Container>
                {children}
            </Container>
        </>
    )
}

export default Modal;

const Wrapper = styled.div`
  position: fixed;
  ${({theme}) => theme.allOfCenter};
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 557;
  background-color: rgba(0, 0, 0, 0.74);
`
const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  ${({theme}) => theme.allOfCenter};
  flex-direction: column;
  width: 53.2rem;
  height: auto;
  padding: 4rem 4rem 2.4rem 4rem ;
  z-index: 558;
  background: white;
  transform: translate(-50%, -50%);
  border-radius: 1rem;

  @media screen and (max-width: 480px) {
    width: 90%;
    height: ${props => props.$size === 'insuranceOutLink' ? '34rem' : 'auto'};
    padding: 4rem 2rem 2.4rem 2rem ;

  }
`