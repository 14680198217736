import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import GlobalStyle from "./styles/GlobalStyle";
import {ThemeProvider} from "styled-components";
import theme from "./styles/theme";
import {RecoilRoot} from 'recoil';
// import './index.css';
import {QueryClient, QueryClientProvider} from "react-query";


const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
    defaultOptions:{
        queries:{
            refetchOnWindowFocus :false,
        }
    }
});


root.render(
    <>
        <GlobalStyle/>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <RecoilRoot>
                    <App/>
                </RecoilRoot>
            </ThemeProvider>
        </QueryClientProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
