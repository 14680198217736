import {useState} from 'react';
import {useNavigate} from "react-router";
import {useEffect} from "react";
import * as S from './styles/SideBarStyles'
import {PetfinsLogoIcon} from "./styles/SideBarStyles";
import {getCookie, removeCookie} from "../../services/loginCookie";
import {requestGet} from "../../services/request";
import {useRecoilState} from "recoil";
import {recommenderStatus} from "../../recoil/store";

const SideBar = ({handleSideBar}) => {

    const [openMenuId, setOpenMenuId] = useState(0);
    const [myInfoData, setMyInfoData] = useState({})
    const [recommenderState, setRecommenderState] = useRecoilState(recommenderStatus)
    const [serviceOnOff, setServiceOnOff] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    // 사이드바 열렸을 때 뒷 배경 움직이지 않게 처리
    useEffect(() => {
        document.getElementById('sidebar').style.cssText = `
        position: fixed;
    top: 0;`;
        document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
        return () => {
            document.body.style.cssText = '';
            window.scrollTo(0, 0);
        };
    }, []);


    // 메뉴 누르면 페이지 이동 + 사이드 바 닫힘
    const handleMovePage = (pathName) => {
        navigate(pathName)
        handleSideBar();
        // window.location.href = pathName;
    }

    // 활성화된 메뉴 색상 변경을 위해서 useState에 메뉴Id 저장
    const handleOpenMenu = (menuId) => {
        if (openMenuId === menuId) {
            setOpenMenuId(0)
        } else {
            setOpenMenuId(menuId)
        }
    }


    const getMyInfo = async () => {
        try {
            const res = await requestGet('/mypage/myinfo')

            if (res.code === 0) {
                setMyInfoData(res.data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {

        // 로그인이 되어있을 때만 api 호출
        if (getCookie('pid_ys') === 0) {
            getMyInfo()
        }
    }, [getCookie('pid_ys')])

    useEffect(() => {
        try {
            checkServiceOnOff()
            console.log(serviceOnOff)
        } catch (error) {
            setLoading(false)
        }
    }, [])


    const handleLogout = async () => {
        const res = await requestGet('/public/user/signout')

        if (res.code === 0) {
            sessionStorage.clear()
            localStorage.clear()
            removeCookie('pid_ys')
            window.location.replace('/');
            handleSideBar()
        }
    }


    //추천인 접수 상태에 따라서 페이지 이동시키는 함수
    const checkApplyRecommender = async () => {
        try {
            if (getCookie('pid_ys') !== 0) {
                handleSideBar()
                navigate('/login')
            } else {
                const res = await requestGet('/recommender/state')

                if (res.code == 0) {
                    if (res.data.stateCode === 0) {
                        handleSideBar()
                        navigate('/applyforrecommender');
                    } else {
                        setRecommenderState(res.data)
                        handleSideBar()
                        navigate(`/recommender/accessrestriction/${res.data.stateCode}`)
                    }

                }
            }
        } catch (e) {
            console.log(e)

        }

    }

    // 서비스 중단, 종료 체크
    const checkServiceOnOff = async () => {
        try {
            const res = await requestGet('/public/serviceonoff')

            if (res.code === 0) {
                setServiceOnOff(res.data)
            }
        } catch (e) {
            console.log(e);
        }
    }


    return (
        <>

            <S.Wrapper id='sidebar'
                       onClick={handleSideBar}/>
            <S.Container
                initial={{x: -500}}
                animate={{x: 0}}
                transition={{ease: "easeOut", duration: 0.3}}>
                <S.Header>
                    <S.Top>
                        <PetfinsLogoIcon onClick={() => handleMovePage('/')}/>
                        <S.Icons>

                            {/*알람 기능 보류 (추후 개발 시 주석 풀어서 사용)*/}
                            {/*<S.AlarmBox>*/}
                            {/*    <S.AlarmIcon/>*/}
                            {/*    <S.New></S.New>*/}
                            {/*</S.AlarmBox>*/}

                            <S.XIcon onClick={handleSideBar}/>
                        </S.Icons>
                    </S.Top>
                    <S.Bottom>
                        {myInfoData?.name
                            ?
                            <button onClick={() => handleMovePage('/mypage/myinfoadmin')}>
                                <p>{myInfoData?.name}님</p>
                                <S.RigthArrowIcon width={'24'} height={'24'}/>
                            </button>
                            :
                            <button onClick={() => handleMovePage('/login')}>
                                <p>로그인이 필요합니다.</p>
                                <S.RigthArrowIcon width={'24'} height={'24'}/>
                            </button>
                        }

                    </S.Bottom>
                </S.Header>
                <S.Line/>
                <S.MenuList>
                    <S.Menu $active={openMenuId === 1}
                            onClick={() => handleOpenMenu(1)}>
                        <S.Icon1 $active={openMenuId === 1}/>
                        <p>펫보험</p>
                        <S.DropDownIcon $active={openMenuId === 1}/>
                    </S.Menu>
                    {openMenuId === 1 &&
                        <S.SubMenuList>
                            {!loading && serviceOnOff[0].useYn === 'n'
                                ? <></>
                                : <S.SubMenu onClick={() => handleMovePage('/comparecommonpetins')}>
                                    펫보험 가입하기
                                </S.SubMenu>
                            }
                            {!loading && serviceOnOff[1].useYn === 'n'
                                ? <></>
                                : <S.SubMenu onClick={() => handleMovePage('/wilddoginsurance')}>
                                    맹견 보험 가입하기
                                </S.SubMenu>
                            }
                            <S.SubMenu onClick={() => handleMovePage('/premiumcalculator')}>
                                계산하기
                            </S.SubMenu>
                            <S.SubMenu onClick={() => handleMovePage('/mypage/claimadmin')}>
                                청구하기
                            </S.SubMenu>
                            <S.SubMenu onClick={() => handleMovePage('/checkmedicalexpenses')}>
                                의료비 알아보기
                            </S.SubMenu>
                            <S.SubMenu onClick={() => handleMovePage('/paymentcase')}>
                                보험금 지급사례
                            </S.SubMenu>

                        </S.SubMenuList>
                    }
                    <S.Menu $active={openMenuId === 2}
                            onClick={() => handleOpenMenu(2)}
                    >
                        <S.Icon2 $active={openMenuId === 2}/>
                        <p>추천인</p>
                        <S.DropDownIcon $active={openMenuId === 2}/>
                    </S.Menu>
                    {openMenuId === 2 &&
                        <S.SubMenuList>
                            {!loading && serviceOnOff[2].useYn === 'n'
                                ? <></>
                                : <S.SubMenu onClick={() => handleMovePage('/becomerecommender')}>
                                    추천인 활동하기
                                </S.SubMenu>
                            }
                            {!loading && serviceOnOff[2].useYn === 'n'
                                ? <></>
                                : <S.SubMenu onClick={checkApplyRecommender}>
                                    추천인 신청하기
                                </S.SubMenu>
                            }
                            <S.SubMenu onClick={() => handleMovePage('/whatisrecommender')}>
                                추천인이란?
                            </S.SubMenu>
                        </S.SubMenuList>
                    }
                    <S.Menu $active={openMenuId === 3}
                            onClick={() => handleOpenMenu(3)}
                    >
                        <S.Icon3 $active={openMenuId === 3}/>
                        <p>커뮤니티</p>
                        <S.DropDownIcon $active={openMenuId === 3}/>
                    </S.Menu>
                    {openMenuId === 3 &&
                        <S.SubMenuList>
                            <S.SubMenu onClick={() => handleMovePage('/petdictionary')}>
                                반려동물보험연구소
                            </S.SubMenu>
                            <S.SubMenu onClick={() => handleMovePage('/community')}>
                                반려동물 보물
                            </S.SubMenu>
                            <S.SubMenu onClick={() => handleMovePage('/lookalikediscriminator')}>
                                닮은꼴 반려견 찾기
                            </S.SubMenu>
                        </S.SubMenuList>
                    }
                    <S.Menu $active={openMenuId === 4}
                            onClick={() => handleOpenMenu(4)}
                    >
                        <S.Icon4 $active={openMenuId === 4}/>
                        <p>회사소개</p>
                        <S.DropDownIcon $active={openMenuId === 4}/>

                    </S.Menu>
                    {openMenuId === 4 &&
                        <S.SubMenuList>
                            <S.SubMenu onClick={() => handleMovePage('/aboutus')}>
                                회사소개
                            </S.SubMenu>
                            <S.SubMenu onClick={() => handleMovePage('/pressrelease')}>
                                언론보도
                            </S.SubMenu>
                        </S.SubMenuList>
                    }
                    <S.Menu $active={openMenuId === 5}
                            onClick={() => handleOpenMenu(5)}
                    >
                        <S.Icon5 $active={openMenuId === 5}/>
                        <p>고객센터</p>
                        <S.DropDownIcon $active={openMenuId === 5}/>
                    </S.Menu>
                    {openMenuId === 5 &&

                        <S.SubMenuList>
                            <S.SubMenu onClick={() => handleMovePage('/faq')}>
                                자주하는 질문
                            </S.SubMenu>
                            <S.SubMenu onClick={() => handleMovePage('/notice')}>
                                공지사항
                            </S.SubMenu>
                            <S.SubMenu onClick={() => handleMovePage('/inquiry')}>
                                1:1 문의하기
                            </S.SubMenu>
                        </S.SubMenuList>
                    }
                    <S.Menu
                        $active={openMenuId === 6}
                        onClick={() => handleOpenMenu(6)}
                    >
                        <S.Icon6 $active={openMenuId === 6}/>
                        <p>AI</p>
                        <S.DropDownIcon $active={openMenuId === 6}/>
                    </S.Menu>
                    {openMenuId === 6 &&
                        <S.SubMenuList>
                            <S.SubMenu onClick={() => handleMovePage('/newbreeddiscriminator')}>
                                품종판별
                            </S.SubMenu>
                            <S.SubMenu onClick={() => handleMovePage('/supportprediction/1')}>
                                병원비 예측
                            </S.SubMenu>
                        </S.SubMenuList>
                    }
                    {getCookie('pid_ys') === 0 &&
                        <S.Menu
                            onClick={() => {
                                handleLogout()
                            }}
                        >
                            <S.Icon7/>
                            <p>로그아웃</p>
                        </S.Menu>
                    }
                </S.MenuList>
            </S.Container>
        </>
    );
};

export default SideBar;
