import {useEffect, useState} from 'react';
import styled from "styled-components";
import {requestGet} from "../../services/request";
import dompurify from "dompurify";
import {useSetTitle} from "../../services/useSetTitle";

function TermsOfService() {
    const [termData, setTermData] = useState([]);


    const getTermsOfService = async ()=>{
        const res = await requestGet('/public/term');

        if (res.code === 0) {
            setTermData(res.data.termList[3]);

        }
    }

    useEffect(() => {
        getTermsOfService();
    }, [])


    const sanitizer = dompurify.sanitize;

    useSetTitle('펫핀스 - 서비스 이용약관')


    return (
        <>
            <Wrapper>
                <Container>
                    <p>서비스 이용약관</p>
                    <Content dangerouslySetInnerHTML={{__html: sanitizer(termData.content)}} />
                </Container>
            </Wrapper>
        </>
    );
}

export default TermsOfService;

const Wrapper = styled.div`
  ${({theme}) => theme.allOfCenter};
  flex-direction: column;
  width: 100vw;
  margin: 12rem 0 20rem;

  @media screen and (max-width: 480px){
    margin: 7.6rem 0 16rem;
  }
`

const Container = styled.div`
  width: 71.4rem;

  >p{
    ${({theme})=>theme.font.pageTitle};
    font-weight: bold;
    margin-bottom: 2.2rem;
  }
  @media screen and (max-width: 480px){
    width: 100%;
    padding:0 1.6rem;

    >p{
      font-size:2.2rem;
      line-height: 2.4rem;
      margin-bottom:0;
    }
  }
`

const Content = styled.div`
  text-align: left !important;
  display: flex !important;
  flex-direction: column !important;;
  align-items: flex-start !important;
  
  >div{
    >strong{
        >span{
        display: none !important;
      }
    }
  }
  
  span {
    font-size: 1.6rem !important;
    white-space: pre-wrap;
    line-height: 2.2rem;
    color:${({theme})=>theme.colors.gray6};
    @media screen and (max-width: 480px){
      ${({theme})=>theme.font.error};
    }
  }

 
`