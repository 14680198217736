import styled from "styled-components";

export const MobileWrapper = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  height: 5.6rem;
  background: white;
  z-index: 2;
  padding:1.4rem 1.6rem;
  border-bottom: 1px solid ${({theme})=>theme.colors.gray};

  
  h1{
    display: block;
    overflow: hidden;
    font-size: 1px;
    line-height: 0;
    color: transparent;
  }
  a {
    text-decoration: none;
  }
  picture{
    width: 11.8rem;
    height: auto;
    img{
      width: 11.8rem;
      height: auto;
    }
  }
 
  
`;

export const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 8rem;
  background: white;
  z-index: 2;

  h1{
    display: block;
    overflow: hidden;
    Font-size: 1px;
    line-height: 0;
    text-indent: -9999px;
  }
  
  a {
    text-decoration: none;
  }
  
`;


export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 108rem;
  min-width: 108rem;

`;


