import styled from "styled-components";
import {Link} from "react-router-dom";

export const Container = styled.div`
  display: flex;
  width: auto;

`

export const GoToSignUp = styled(Link)`
  display: flex;
justify-content: center;
  align-items: center;
  padding: 0.9rem 1.6rem;
  color: ${props => props.$active ? ({theme})=>theme.colors.main : ({theme})=>theme.colors.gray7};
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.2rem;
  cursor: pointer;

  &:hover {
    transition: 0.3s all;
  }
`;


export const GoToLogin = styled(GoToSignUp)`
  ${({theme}) => theme.button.confirm};
  color:black;

  &:hover {
    transition: 0.3s all;
  }

`;

export const LogoutBtn = styled.button`
  display: block;
  padding: 0.9rem 1.6rem;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.2rem;
  ${({theme}) => theme.button.confirm};
  color:black;

  &:hover {
    transition: 0.3s all;
  }
`