import styled from "styled-components";
import {motion} from "framer-motion";
import {ReactComponent as PetfinsLogo} from '../../svg/petfins_logo.svg';
import {ReactComponent as Alarm} from '../../svg/alarm_icon.svg';
import {ReactComponent as X} from '../../svg/x.svg';
import {ReactComponent as BoldDropDown} from '../../svg/dropdown_bold.svg';
import {ReactComponent as DropDown} from '../../svg/dropdown.svg';
import {ReactComponent as PetInsurance} from '../../svg/petinsurance_icon.svg';
import {ReactComponent as Supporter} from '../../svg/supporter_icon.svg';
import {ReactComponent as Commnuity} from '../../svg/community_icon.svg';
import {ReactComponent as AboutUs} from '../../svg/aboutus_icon.svg';
import {ReactComponent as AS} from '../../svg/a:s_icon.svg';
import {ReactComponent as BreedDiscriminator} from '../../svg/breeddiscriminator_icon.svg';
import {ReactComponent as Logout} from '../../svg/logout.svg';


export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 556;
  background: ${({theme})=>theme.colors.customBlack};
`

export const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 28.8rem;
  height: 100%;
  background: white;
  z-index: 558;
`

export const Header = styled.header`
  width: 100%;
  padding: 4rem 1.6rem 2.4rem 1.6rem;
`

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;

`

export const PetfinsLogoIcon = styled(PetfinsLogo)`
  width: 9.6rem;

`

export const Icons = styled.div``

export const AlarmBox = styled.div`
  position: relative;
  top:0;
  right: 3.8rem;
  margin-right: 1.6rem;
`

export const AlarmIcon = styled(Alarm)`
  position: absolute;
`
export const New = styled.div`
  position: absolute;
  top:0;
  left: 1.8rem;
  width: 5px;
  height: 5px;
  border-radius: 999px;
  background: ${({theme})=>theme.colors.main};

`

export const XIcon = styled(X)``


export const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

button{
  display: flex;
  align-items: center;
}
  p{
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${({theme})=>theme.colors.customBlack};
  }

`

export const RigthArrowIcon = styled(BoldDropDown)`
  rotate: 270deg;
  width: 2.4rem;
height: 2.4rem;
`

export const MenuList = styled.ul`
width:100%;
  margin:0.8rem 0 0 0;
  overflow: scroll;

  ::-webkit-scrollbar{
    display: none;
  }
`

export const Menu = styled.li`
width: 100%;
  display: flex;
  align-items: center;
  position:relative;
padding:1.4rem 1.6rem;
  cursor: pointer;
  
  >p{
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    color:${props => props.$active ? ({theme})=>theme.colors.main : ({theme})=>theme.colors.customBlack};
    margin-top:0.4rem;
  }

 
`

export const Icon1 = styled(PetInsurance)`
  width: 2.4rem;
height: 2.4rem;
  margin-right: 0.8rem;

   > g{
    > path{
        fill:${props => props.$active ? ({theme})=>theme.colors.main : ({theme})=>theme.colors.customBlack} !important;
      }
    }
  
`

export const Icon2 = styled(Supporter)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;

  > g{
    >g{
      > path{
        fill:${props => props.$active ? ({theme})=>theme.colors.main : ({theme})=>theme.colors.customBlack} !important;
      }
    }
  
  }

`

export const Icon3 = styled(Commnuity)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;

  > g{
    >g{
      > path{
        fill:${props => props.$active ? ({theme})=>theme.colors.main : ({theme})=>theme.colors.customBlack} !important;
      }
    }

  }


`
export const Icon4 = styled(AboutUs)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
  > g{
    >g{
      > path{
        fill:${props => props.$active ? ({theme})=>theme.colors.main : ({theme})=>theme.colors.customBlack} !important;
      }
    }

  }

`
export const Icon5 = styled(AS)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;

  > g{
    > path{
      fill:${props => props.$active ? ({theme})=>theme.colors.main : ({theme})=>theme.colors.customBlack} !important;
    }
  }

`
export const Icon6 = styled(BreedDiscriminator)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;

  > g{
    > g {
      > g {
        > path {
          fill: ${props => props.$active ? ({theme}) => theme.colors.main : ({theme}) => theme.colors.customBlack} !important;
        }
      }
    }

`

export const Icon7 = styled(Logout)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
`

export const DropDownIcon = styled(DropDown)`
  width: 2.4rem;
height: 2.4rem;
  position:absolute;
  right: 1.6rem;
  rotate:${props => props.$active ? '180deg' : '0'};
  transition:0.2s all;
`

export const SubMenuList = styled.ul`
width: 100%;

`

export const SubMenu = styled.li`
width: 100%;
  display: block;
  text-decoration: none;
  padding: 1.1rem 4.8rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
  color:${({theme})=>theme.colors.gray7};

  :hover{
    background: ${({theme})=>theme.colors.gray2};
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;

  h1 {
    font-size: 2rem;
    font-weight: bold;
  }
`

export const Line = styled.hr`
  border: solid 0.05rem #e3e3e3;
  color: #e3e3e3;
`
