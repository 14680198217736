import styled from "styled-components";
import {useEffect, useState} from "react";
import {cookieSetting, requestGet, requestPost} from "../../services/request.js";
import Loading from "../../components/Loading.jsx";
import {useSearchParams} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {userIdData} from "../../recoil/store";
import Modal from "../../components/Modal/Modal";
import {ModalBtn, ModalContent, ModalTitle} from "../../components/Modal/styles/ModalStyles";
import {useNavigate} from "react-router";

const NaverLoginCallback = () => {
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams()

    const code = searchParams.get('code');
    const state = searchParams.get('state');

    const setUserId = useSetRecoilState(userIdData)
    const [showModal, setShowModal] = useState(false);

    const handleModal = () => {
        setShowModal(!showModal);
    }
    const navigate = useNavigate()


    const naverLogin = async () => {


        const token = await requestGet("/naver/token", {
            params: {
                code: code,
                state: state
            },
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (token.code === 0) {

            const naverLogin = await requestPost(`/naver/signhandler`, {
                ...token.data,
                isAuto: 'n'
            });


            if (naverLogin.code === 0) {

                await requestPost(`/public/user/loginlog/${naverLogin.data.id}`)

                setLoading(false);

                setUserId(naverLogin.data.id)

                const href = localStorage.getItem("location");
                cookieSetting('n', naverLogin.code)

                if (href === null || typeof href === 'undefined' || href === '' || href === '/') {
                    window.location.replace('/')

                } else {
                    window.location.replace(href)
                }

            } else if (naverLogin.code === 1012) {
                setLoading(false);
                handleModal()

            } else {
                setLoading(false);
                alert('로그인을 다시 시도해주세요')
            }

        } else {
            setLoading(false);
            alert('로그인을 다시 시도해주세요')
        }


    }

    useEffect(() => {
        setLoading(true)
        naverLogin();

    }, [])

    const prevNavigate = ()=>{

        const href = localStorage.getItem("location");

        if (href === null || typeof href === 'undefined' || href === '' || href === '/') {
            handleModal()

            navigate('/login')

        } else {
            handleModal()

            navigate('/login',{
                state:{
                    pathname:href
                }
            })
        }
    }



    return (
        <>
            {showModal &&
                <Modal handleModal={() => {
                    prevNavigate()

                }
                }>
                    <ModalTitle>
                        이미 가입된 회원입니다.
                    </ModalTitle>
                    <ModalContent>
                        로그인을 다시 시도해주세요.
                    </ModalContent>
                    <ModalBtn onClick={() => {
                        prevNavigate()


                    }}>확인</ModalBtn>
                </Modal>
            }
            <Wrapper>
                {loading ? <Loading/> : ''}
            </Wrapper>
        </>
    )

}

export default NaverLoginCallback;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`