import {ThreeDots} from "react-loader-spinner";
import styled from 'styled-components';

//일반적인 로딩 컴포넌트
const Loading = () => {

    return (
        <Wrapper>
            <ThreeDots
                color='#FF4E00'
                height={100}
                width={100}
                ariaLabel="three-dots-loading"
                wrapperClass='spinner'
                visible={true}
            />
        </Wrapper>
    );
};

export default Loading;

const Wrapper = styled.div`
  position: fixed;
  ${({theme})=>theme.allOfCenter};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 997;
  background: white;
`