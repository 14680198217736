import axios from 'axios';
import {REACT_APP_BASE_URL} from "../config";

const client = axios.create({
    headers:{
        'Access-Control-Allow-Origin':`${process.env.REACT_APP_BASE_URL}`,
        'Access-Control-Allow-Credentials':true,
    },
    withCredentials: true,
    baseURL:`${process.env.REACT_APP_BASE_URL}`,
    // httpsAgent: new https.Agent({
    //     rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
    // }),
})


/*client.interceptors.request.use(
    config =>{
        const token = localStorage.getItem("accessToken");
        if(token){
            config.headers['x-auth-token'] = token;
        }
    }
)*/

export const setClient = (token) => {
    client.defaults.headers.common['x-auth-token'] = token
}

export {client};
