export const navMenu = {
        first: [{'펫보험 가입하기': '/comparecommonpetins'},{'맹견보험 가입하기': '/wilddoginsurance'}, {'계산하기': '/premiumcalculator'}, {'청구하기': '/mypage/insuranceadmin'},  {'의료비 알아보기': '/checkmedicalexpenses'},{'보험금 지급사례': '/paymentcase'}],
        second: [{'추천인 활동하기': '/becomerecommender'}, {'추천인 신청하기': '/applyforrecommender'}, {'추천인이란?': '/whatisrecommender'}],
    third: [{"반려동물보험연구소": '/petdictionary'}, {"반려동물 보물": '/community'}, {"닮은꼴 반려견 찾기": '/lookalikediscriminator'}],
    fourth: [{"회사소개": '/aboutus'}, {"언론보도": '/pressrelease'}],
    fifth: [{"자주하는 질문": '/faq'}, {"공지사항": '/notice'}, {"1:1 문의하기": '/inquiry'}],
    sixth: [{"품종판별": '/newbreeddiscriminator'}, {"병원비 예측": '/supportprediction/1'}],
    }
;

