import {createGlobalStyle} from 'styled-components';
import reset from 'styled-reset';

const global = createGlobalStyle`
  ${reset}

  ;


  * {
    box-sizing: border-box;
    text-decoration: none;
    margin: 0;
    padding: 0;
    font-family: 'Pretendard','Apple SD Gothic Neo','sans-serif' !important;
    color:#101010;
    user-select: none;

  }

  html {
    font-size: 62.5%;
    height: 100%;
  }
  

  body {
    overflow-x: hidden;
    font-family: 'Pretendard','Apple SD Gothic Neo','sans-serif' !important;
    min-width: 360px;

    @media screen and (max-width: 480px) {
      overflow: scroll;
    }

  }

  #root {
    //min-height: calc(100vh - 24rem);
    //min-height: 100%;
    //position: relative;
    //padding-bottom: 24rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    @media screen and (max-width: 480px) { 
      overflow-x: hidden;
      //display: flex;
      //flex-direction: column;
      //min-height: 100vh;
      //position:static;
      //padding-bottom: 0;
    }
  }

  input,
  button {
    border: none;
  }

  button {
    background: transparent;
    cursor: pointer;
    text-align: center;
  }

  input:focus,
  input:active,
  button:focus,
  button:active,
  select, textarea{
    outline: none;
  }

  img {
    -webkit-user-drag: none;
  }

  select{
    :required:invalid {
      color:#ACACAC;
    }

    option{
      color: #101010;
    }

    option[value=""][disabled] {
      display:none
    }
  }

  a{
    text-decoration: none;
  }
`;

export default global;
