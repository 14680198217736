import styled from 'styled-components';
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {useSetTitle} from "../services/useSetTitle";

function SignUpInsuranceComplete(props) {

    const location = useLocation()

    useSetTitle('펫핀스 - 가입 완료')


    return (
        <Wrapper>
            <Container>
                <picture>
                    <source srcSet={`https://petfins.cdn.ntruss.com/icons/bill.webp`} type={'image/webp'} />
                    <img src={`https://petfins.cdn.ntruss.com/icons/bill.png`} alt={''} />
                </picture>
                {location.pathname.includes('renew')
                ?
                    <>
                        <p>갱신이 완료되었습니다.</p>
                        <p>마이페이지에서 상세내역을 확인해보세요</p>

                    </>
                    :
                    <>
                        <p>보험 가입이 완료되었습니다.</p>
                        <p>마이페이지에서 상세내역을 확인해 보세요.</p>
                    </>
                }


                <Link to={'/mypage/insuranceadmin'}>보험내역 확인하기</Link>
            </Container>
        </Wrapper>

    );
}

export default SignUpInsuranceComplete;

const Wrapper = styled.div`
width: 100%;
  display: flex;
  justify-content: center;
  margin-top:8rem;

  @media screen and (max-width: 480px){
    margin-top:5.6rem;

  }
`

const Container = styled.div`
  width: 53.2rem;
margin:18rem 0 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  
    >picture{
      width: 18rem;
      height: 18rem;
      
      >img{
        width: 100%;
        height: 18rem;
        margin-bottom: 3.2rem;
      }
    }
  
  >p{
    
    :nth-of-type(1){
      ${({theme})=>theme.font.pageTitle};
      font-weight: 700;
    margin-bottom: 1.8rem;
    }
    :nth-of-type(2){
      font-size: 1.8rem;
      line-height: 2.2rem;
    margin-bottom: 6.4rem;
    }
  }
  
  >a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5.4rem;
    ${({theme})=>theme.button.active1};
    font-size:1.8rem;
    line-height: 2rem;
    font-weight: 500;
  }
  
  @media screen and (max-width: 480px){
    width: 100%;
    padding:0 1.6rem;
    margin:15.6rem 0 20rem;

    >picture{
      width: 16rem;
      height: 16rem;

      >img{
        width: 100%;
        height: 16rem;
        margin-bottom: 2.4rem;
      }
    }

    >p{

      :nth-of-type(1){
        font-size:2.2rem;
        line-height: 2.4rem;
        margin-bottom: 0.8rem;
      }
      :nth-of-type(2){
        font-size: 1.6rem;
        margin-bottom: 3.4rem;
        text-align: center;
      }
    }

    >a{
      height: 5.2rem;
      ${({theme})=>theme.font.contentTitle};
    }
  }
`


