import * as S from "../styles/LeftStyles";
import {Link} from "react-router-dom";
import {menuAnimate} from "../../../styles/animate";
import {navMenu} from "../../../models/NavData";
import {useEffect, useState} from "react";
import {requestGet} from "../../../services/request";
import {useNavigate} from "react-router";
import {useRecoilState} from "recoil";
import {recommenderStatus} from "../../../recoil/store";
import {getCookie} from "../../../services/loginCookie";

function Left(props) {

    const [showFirstMenu, setShowFirstMenu] = useState(false);
    const [showSecondMenu, setShowSecondMenu] = useState(false);
    const [showThirdMenu, setShowThirdMenu] = useState(false);
    const [showFourthMenu, setShowFourthMenu] = useState(false);
    const [showFifthMenu, setShowFifthMenu] = useState(false);
    const [showSixthMenu, setShowSixthMenu] = useState(false);
    const [serviceOnOff, setServiceOnOff] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate()

    const [recommenderState, setRecommenderState] = useRecoilState(recommenderStatus)


    //추천인 접수 상태에 따라서 페이지 이동시키는 함수
    const checkApplyRecommender = async () => {
        try {

            if (getCookie('pid_ys') !== 0) {
                navigate('/login')
            } else {
                const res = await requestGet('/recommender/state')

                if (res.code == 0) {
                    if (res.data.stateCode === 0) {
                        navigate('/applyforrecommender');
                    } else {
                        setRecommenderState(res.data)
                        navigate(`/recommender/accessrestriction/${res.data.stateCode}`)
                    }

                }
            }

        } catch (e) {
            console.log(e)

        }
    }

    // 서비스 중단, 종료 체크
    const checkServiceOnOff = async () => {
        try {
            const res = await requestGet('/public/service-onoff/list')

            if (res.code === 0) {
                setServiceOnOff(res.data);
                setLoading(false)
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    useEffect(() => {
        try {
            checkServiceOnOff()
            console.log(serviceOnOff)
        } catch (error) {
            setLoading(false)
        }
    }, [])

    return (
        <S.Container>
            <Link to='/' title={'펫핀스 메인 페이지로 이동'}>
                <picture>
                    <source
                        srcSet={`https://petfins.cdn.ntruss.com/icons/logo%28width%29_pc.avif`}
                        type={'image/avif'}/>
                    <source
                        srcSet={`https://petfins.cdn.ntruss.com/icons/logo%28width%29_pc.webp`}
                        type={'image/webp'}/>
                    <img
                        src={`https://petfins.cdn.ntruss.com/icons/logo%28width%29_pc.png`}
                        alt={'펫핀스'}
                        width={'166px'}
                        height={'46px'}
                        style={{aspectRatio: '3.6 / 1', height: 'auto'}}
                    />
                </picture>
            </Link>

            <S.MenuContainer>
                <li>
                    <S.KindOfMenu
                        onMouseOver={() => {
                            setShowFirstMenu(true);
                        }}
                        onMouseLeave={() => {
                            setShowFirstMenu(false);

                        }}>
                        <S.FirstMenu
                            $active={showFirstMenu}
                            onMouseOver={() => {
                                setShowFirstMenu(true);

                            }}
                            onMouseLeave={() => {
                                setShowFirstMenu(false);
                            }}>
                            {!loading && serviceOnOff[0].useYn === 'n'
                            ? <Link to={''}>펫보험</Link>
                            : <Link to='/comparecommonpetins'>
                                    펫보험
                                </Link>
                            }
                        </S.FirstMenu>

                        {showFirstMenu && (
                            <li>
                                <S.FirstMenuBox
                                    onMouseLeave={() => {
                                        setShowFirstMenu(false)
                                    }}
                                    initial='exit'
                                    animate={showFirstMenu ? 'enter' : 'exit'}
                                    variants={menuAnimate}
                                >
                                    {navMenu["first"].map((menu, i) => {
                                        return (
                                            <S.FirstSubMenu key={i}
                                                            onClick={() => setShowFirstMenu(false)}>
                                                {(!loading && serviceOnOff[0].useYn === 'n' && Object.keys(menu).at(0) === '펫보험 가입하기')
                                                    || (!loading && serviceOnOff[1].useYn === 'n' && Object.keys(menu).at(0) === '맹견보험 가입하기')
                                                    ? <Link to={''} style={{color:"white"}}>
                                                        {Object.keys(menu)}
                                                    </Link>
                                                    : <Link to={menu[Object.keys(menu)]}>
                                                        {Object.keys(menu)}
                                                    </Link>
                                                }
                                            </S.FirstSubMenu>
                                        );
                                    })}
                                </S.FirstMenuBox>
                            </li>
                        )}
                    </S.KindOfMenu>
                </li>

                <li>
                    <S.KindOfMenu
                        onMouseOver={() => {
                            setShowSecondMenu(true);
                        }}
                        onMouseLeave={() => {
                            setShowSecondMenu(false);
                        }}
                    >

                        <S.SecondMenu
                            $active={showSecondMenu}
                            onMouseOver={() => {
                                setShowSecondMenu(true);
                            }}
                            onMouseLeave={() => {
                                setShowSecondMenu(false);
                            }}
                        >
                            {!loading && serviceOnOff[2].useYn === 'n'
                                ? <button onClick={() => {
                                    navigate('')
                                }}>
                                    추천인
                                </button>
                                : <button onClick={() => {
                                    navigate('/whatisrecommender')
                                }}>
                                    추천인
                                </button>
                            }


                        </S.SecondMenu>

                        {showSecondMenu && (
                            <li>
                                <S.SecondMenuBox
                                    onMouseLeave={() => {
                                        setShowSecondMenu(false)
                                    }}
                                    initial='exit'
                                    animate={showSecondMenu ? 'enter' : 'exit'}
                                    variants={menuAnimate}
                                >
                                    {navMenu["second"].map((menu, i) => {
                                        return (
                                            <S.SecondSubMenu key={i}
                                                             onClick={() => setShowSecondMenu(false)}>
                                                {!loading && serviceOnOff[2].useYn === 'n' && (Object.keys(menu).at(0) === '추천인 활동하기'
                                                || Object.keys(menu).at(0) === '추천인 신청하기')
                                                ? <button onClick={() => {
                                                    navigate('')}
                                                    } style={{color:"white"}}>{Object.keys(menu)}</button>
                                                : <button onClick={() => {
                                                        if (menu[Object.keys(menu)] === '/applyforrecommender') {
                                                            checkApplyRecommender()
                                                        } else {
                                                            navigate(menu[Object.keys(menu)])
                                                        }
                                                    }}>
                                                        {Object.keys(menu)}
                                                    </button>
                                                }
                                            </S.SecondSubMenu>
                                        );
                                    })}
                                </S.SecondMenuBox>
                            </li>
                        )}
                    </S.KindOfMenu>
                </li>

                <li>
                    <S.KindOfMenu
                        onMouseOver={() => {
                            setShowThirdMenu(true);
                        }}
                        onMouseLeave={() => {
                            setShowThirdMenu(false);
                        }}

                    >

                        <S.ThirdMenu
                            $active={showThirdMenu}
                            onMouseOver={() => {
                                setShowThirdMenu(true);
                            }}
                            onMouseLeave={() => {
                                setShowThirdMenu(false);
                            }}>
                            <Link to='/community'>
                                커뮤니티
                            </Link>
                        </S.ThirdMenu>

                        {showThirdMenu && (
                            <li>
                                <S.ThirdMenuBox
                                    onMouseLeave={() => {
                                        setShowThirdMenu(false)
                                    }}
                                    initial='exit'
                                    animate={showThirdMenu ? 'enter' : 'exit'}
                                    variants={menuAnimate}
                                >
                                    {navMenu["third"].map((menu, i) => {
                                        return (
                                            <S.ThirdSubMenu key={i}
                                                            onClick={() => setShowThirdMenu(false)}>
                                                <Link to={menu[Object.keys(menu)]}>
                                                    {Object.keys(menu)}
                                                </Link>
                                            </S.ThirdSubMenu>
                                        );
                                    })}
                                </S.ThirdMenuBox>
                            </li>
                        )}
                    </S.KindOfMenu>
                </li>

                <li>
                    <S.KindOfMenu onMouseOver={() => {
                        setShowFourthMenu(true);
                    }}
                                  onMouseLeave={() => {
                                      setShowFourthMenu(false);
                                  }}
                    >
                        <S.FourthMenu
                            $active={showFourthMenu}
                            onMouseOver={() => {
                                setShowFourthMenu(true);
                            }}
                            onMouseLeave={() => {
                                setShowFourthMenu(false);
                            }}>
                            <Link to='/aboutus'>
                                회사소개
                            </Link>
                        </S.FourthMenu>
                        {showFourthMenu && (
                            <li>
                                <S.FourthMenuBox
                                    onMouseLeave={() => {
                                        setShowFourthMenu(false)
                                    }}
                                    initial='exit'
                                    animate={showFourthMenu ? 'enter' : 'exit'}
                                    variants={menuAnimate}
                                >
                                    {navMenu["fourth"].map((menu, i) => {
                                        return (
                                            <S.FourthSubMenu key={i}
                                                             onClick={() => setShowFourthMenu(false)}>
                                                <Link to={menu[Object.keys(menu)]}>
                                                    {Object.keys(menu)}
                                                </Link>
                                            </S.FourthSubMenu>
                                        );
                                    })}
                                </S.FourthMenuBox>
                            </li>
                        )}


                    </S.KindOfMenu>
                </li>
                <li>
                    <S.KindOfMenu onMouseOver={() => {
                        setShowFifthMenu(true);
                    }}
                                  onMouseLeave={() => {
                                      setShowFifthMenu(false);
                                  }}
                    >

                        <S.FifthMenu
                            $active={showFifthMenu}
                            onMouseOver={() => {
                                setShowFifthMenu(true);
                            }}
                            onMouseLeave={() => {
                                setShowFifthMenu(false);
                            }}
                        >
                            <Link to='/faq'>
                                고객센터
                            </Link>
                        </S.FifthMenu>

                        {showFifthMenu && (
                            <li>
                                <S.FifthMenuBox onMouseLeave={() => {
                                    setShowFifthMenu(false)
                                }}
                                                initial='exit'
                                                animate={showFifthMenu ? 'enter' : 'exit'}
                                                variants={menuAnimate}
                                >
                                    {navMenu["fifth"].map((menu, i) => {
                                        return (
                                            <S.FifthSubMenu key={i}
                                                            onClick={() => setShowFifthMenu(false)}>
                                                <Link to={menu[Object.keys(menu)]}>
                                                    {Object.keys(menu)}
                                                </Link>
                                            </S.FifthSubMenu>
                                        );
                                    })}
                                </S.FifthMenuBox>
                            </li>
                        )}
                    </S.KindOfMenu>
                </li>
                <li>
                    <S.KindOfMenu onMouseOver={() => {
                        setShowSixthMenu(true);
                    }}
                                  onMouseLeave={() => {
                                      setShowSixthMenu(false);
                                  }}>
                        <S.SixthMenu
                            $active={showSixthMenu}
                            onMouseOver={() => {
                                setShowSixthMenu(true);
                            }}
                            onMouseLeave={() => {
                                setShowSixthMenu(false);
                            }}>
                            <Link to='/newbreeddiscriminator'>
                                AI
                            </Link>
                        </S.SixthMenu>
                        {showSixthMenu && (
                            <li>
                                <S.SixthMenuBox onMouseLeave={() => {
                                    // setShowSixthMenu(false)
                                }}
                                                initial='exit'
                                                animate={showSixthMenu ? 'enter' : 'exit'}
                                                variants={menuAnimate}
                                >
                                    {navMenu["sixth"].map((menu, i) => {
                                        return (
                                            <S.SixthSubMenu key={i}
                                                            onClick={() => setShowSixthMenu(false)}>
                                                <Link to={menu[Object.keys(menu)]}>
                                                    {Object.keys(menu)}
                                                </Link>
                                            </S.SixthSubMenu>
                                        );
                                    })}
                                </S.SixthMenuBox>
                            </li>
                        )}
                    </S.KindOfMenu>
                </li>
            </S.MenuContainer>
        </S.Container>
    );
}

export default Left;