import {useState} from 'react';
import * as S from './styles/UnderNavStyles';
import {Link} from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import {useLocation} from "react-router";

function UnderNav(props) {

    const [showSideBar, setShowSideBar] = useState(false);

    const handleSideBar = ()=>{
        setShowSideBar(!showSideBar)
    }

    const location = useLocation()

    return (
        <>
            {showSideBar &&
            <SideBar handleSideBar={handleSideBar} />
            }
        <S.Container>
            <li>
                <button onClick={handleSideBar}>
                    <S.Icon1 />
                    <p>메뉴</p>
                </button>
            </li>
            <S.LinkMenu activate={location.pathname === '/comparecommonpetins'}>
                <Link to={'/comparecommonpetins'}>
                    <S.Icon2  activate={location.pathname === '/comparecommonpetins'}/>
                    <p>펫보험</p>
                </Link>
            </S.LinkMenu>
            <S.LinkMenu activate={location.pathname === '/'}>
                <Link to={'/'}>
                    <S.Icon3  activate={location.pathname === '/'}/>
                    <p>홈</p>
                </Link>
            </S.LinkMenu>
            <S.LinkMenu activate={location.pathname === '/mypage/claimadmin'}>
                <Link to={'/mypage/claimadmin'}>
                    <S.Icon4  activate={location.pathname === '/mypage/claimadmin'}/>
                    <p>청구하기</p>
                </Link>
            </S.LinkMenu>
            <S.LinkMenu activate={location.pathname === '/entermypage'}>
                <Link to={'/entermypage'}>
                    <S.Icon5  activate={location.pathname === '/entermypage'}/>
                    <p>내정보</p>
                </Link>
            </S.LinkMenu>
        </S.Container>
        </>
    );
}

export default UnderNav;