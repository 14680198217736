import styled from "styled-components";

export const ModalTitle = styled.p`
font-size:1.8rem;
  line-height: 2.6rem;
  font-weight: 500;
  text-align: center;
  white-space: pre-wrap;
`

export const ModalContent = styled.p`
margin:0.8rem 0 0;
  font-size:1.6rem;
  line-height: 2.4rem;
  color:${({theme})=>theme.colors.gray6};
  white-space: pre-wrap;
  text-align: center;
  
`

export const Buttons = styled.div`
width: 28.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
margin-top:3.2rem;
  
    >button{
      width: 14rem;
      height: 5.2rem;
      ${({theme})=>theme.button.active1};
      ${({theme})=>theme.font.contentTitle};
      
      :nth-of-type(1){
        ${({theme})=>theme.button.disabled1};
      }
    }
  
  @media screen and (max-height: 480px){
    margin-top:1.2rem;
  }
`


export const ModalBtn = styled.button`
  margin-top:3rem;
  width: 18rem;
  height: 5.2rem;
  ${({theme})=>theme.font.contentTitle};
  ${({theme})=>theme.button.active1};
  @media screen and (max-width: 480px) {
width: 14rem;
    margin-top:2.4rem;

  }
`

