import styled from 'styled-components';
import {ReactComponent as ColumnLine} from "../../svg/line_column.svg";
import {ReactComponent as Facebook} from "../../svg/facebook.svg";
import {ReactComponent as Instagram} from "../../svg/instagram.svg";
import {ReactComponent as Blog} from "../../svg/blog.svg";

export const Wrapper = styled.div`
  width: 100%;
  height: 24rem;
  display: ${props => props.display ? 'none' : 'flex'};
  justify-content: center;
  padding: 3.8rem 0 0 0;
  background: ${({theme}) => theme.colors.gray2};
  margin-top: auto;


  @media screen and (max-width: 480px) {
    padding: 3.2rem 2rem 4rem 2rem;
    height: auto;
    margin-bottom: 7.2rem;
  }

`

export const Container = styled.div`
  width: 108rem;
  min-width: 108rem;
  display: flex;
  justify-content: space-between;


  @media screen and (max-width: 480px) {
    display: block;
    min-width: auto;
  }
`

export const First = styled.div`

  > p {
    :nth-of-type(1) {
      color: ${({theme}) => theme.colors.gray6};
      ${({theme}) => theme.font.error};
      font-weight: 500;
    }

    :nth-of-type(2) {
      margin: 0.8rem 0 1.2rem;
      color: ${({theme}) => theme.colors.gray5};
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  > ul {
    display: flex;
    align-items: center;

    > li {
      > a {
        text-decoration: none;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: ${({theme}) => theme.colors.gray6};
      }
    }
  }
`

export const Second = styled(First)`

  > ul {
    > li {
      :nth-of-type(2) {
        margin: 0 1.2rem;
      }
    }
  }
`

export const ColumnLineIcon = styled(ColumnLine)`
  margin: 0 0.8rem;
`

export const FacebookIcon = styled(Facebook)`
  width: 3rem;
  height: 3rem;
`

export const InstagramIcon = styled(Instagram)`
  width: 3rem;
  height: 3rem;
`

export const BlogIcon = styled(Blog)`
  width: 3rem;
  height: 3rem;
`

export const Third = styled.div`
  margin-top: 2rem;

  > p {
    color: ${({theme}) => theme.colors.gray5};
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 500;
  }

  @media screen and (max-width: 480px) {
    margin: 2rem 0;
  }
`


export const Top = styled.div`
  margin-bottom: 2.4rem;

  > p {
    font-size: 1.2rem;
    line-height: 1.6rem;

    :nth-of-type(1) {
      font-weight: 500;
      color: ${({theme}) => theme.colors.gray6};
    }

    :nth-of-type(2) {
      color: ${({theme}) => theme.colors.gray5};
      margin: 0.6rem 0;
    }
  }

  > ul {
    display: flex;
    align-items: center;

    > li {
      > a {
        color: ${({theme}) => theme.colors.gray6};
        text-decoration: none;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
`

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    :nth-of-type(1) {
      > p {
        font-size: 1.2rem;
        line-height: 1.6rem;

        :nth-of-type(1) {
          font-weight: 500;
          color: ${({theme}) => theme.colors.gray6};
        }

        :nth-of-type(2) {
          color: ${({theme}) => theme.colors.gray5};
          margin: 0.6rem 0 0.8rem;
        }
      }

      > ul {
        display: flex;
        align-items: center;

        > li {
          :nth-of-type(2) {
            > a {
              margin: 0 1.2rem;
            }
          }
        }
      }
    }

    :nth-of-type(2) {
      > p {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 500;
        color: ${({theme}) => theme.colors.gray6};
      }

      > a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 10.4rem;
        height: 3.2rem;
        border-radius: 1.2rem;
        background: white;
        margin-top: 0.8rem;

        > picture {
          width: 1.8rem;
          height: 1.8rem;
          margin: 0 0.2rem 0.3rem 0;

          img {
            width: 100%;
            height: 100%;
          }
        }

        > span {
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: ${({theme}) => theme.colors.gray6};
        }
      }
    }
  }
`