import React, {Suspense, lazy} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Nav from "./components/Nav/Nav.jsx"
import Footer from "./components/Footer/Footer";
import SignHandler from "./pages/Login/SignHandler.js";
import NaverLoginCallback from "./pages/Login/NaverLoginCallback.js";
import PrivateRoute from "./services/PrivateRoute";
import ScrollToTop from "./services/ScrollToTop";
import Loading from "./components/Loading";
import {useMediaQuery} from "react-responsive";
import UnderNav from "./components/UnderNav/UnderNav";
import VirtualAccountIssuedComplete from "./components/VirtualAccountIssuedComplete";
import SignUpInsuranceComplete from "./components/SIgnUpInsuranceComplete";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService/TermsOfService";


function App() {

    const isMobile = useMediaQuery({query: '(min-width:0) and (max-width: 480px)'});


    const ClaimInsurance = lazy(() => import('./pages/ClaimInsurance/ClaimInsurance'));
    const Community = lazy(() => import('./pages/Community/Community'));
    const CommunityPost = lazy(() => import('./pages/Community/CommunityPost'));
    const Main = lazy(() => import('./pages/Main/Main'));
    const Login = lazy(() => import('./pages/Login/Login'));
    const SignUp = lazy(() => import('./pages/SignUp/SignUp'));
    const SignUpComplete = lazy(() => import('./pages/SignUp/components/SignUpComplete'));
    const PetDictionary = lazy(() => import('./pages/PetDictionary/PetDictionary'));
    const PaymentCase = lazy(() => import('./pages/Paymentcase/PaymentCase'));
    const Inquiry = lazy(() => import('./pages/Inquiry/Inquiry'));
    const PressRelease = lazy(() => import('./pages/PressRelease/PressRelease'));
    const InsuranceDetail = lazy(() => import('./pages/InsuranceDetail/InsuranceDetail'));
    const NotFound = lazy(() => import('./pages/NotFound/NotFound'))
    const PremiumCalculator = lazy(() => import('./pages/PremiumCalculator/PremiumCalculator'))
    const LookalikeDiscriminator = lazy(() => import('./pages/LookAlikeDiscriminator/LookAlikeDiscriminator'))
    const RecommenderComplete = lazy(() => import('./pages/Recommender/RecommenderComplete'))
    const FindId = lazy(() => import('./pages/FindId/FindId'));
    const Notice = lazy(() => import('./pages/Notice/Notice'));
    const Faq = lazy(() => import('./pages/Faq/Faq'));
    const NoticePost = lazy(() => import('./pages/Notice/components/NoticePost'));
    const FindPassword = lazy(() => import('./pages/FindPassword/FindPassword'))
    const WhatIsRecommender = lazy(() => import('./pages/Recommender/WhatIsRecommender'))
    const BecomeRecommender = lazy(() => import('./pages/Recommender/BecomeRecommender'))
    const ApplyForRecommender = lazy(() => import('./pages/Recommender/ApplyForRecommender'))
    const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
    const CommunityWriting = lazy(() => import('./pages/Community/CommunityWriting'));
    const Article = lazy(() => import('./pages/PetDictionary/components/Aritcle'));
    const CommentRoom = lazy(() => import('./pages/Community/components/CommentRoom'));
    const FierceDogInsurance = lazy(() => import('./pages/FierceDogInsurance/FierceDogInsurance'));
    const PetInsurance = lazy(() => import('./pages/PetInsurance/PetInsurance'));
    const ClaimComplete = lazy(() => import('./pages/ClaimInsurance/components/ClaimComplete'));
    const FindPasswordComplete = lazy(() => import('./pages/FindPassword/components/FindPasswordComplete'));
    const MyPage = lazy(() => import('./pages/MyPage/MyPage'));
    const EnterMypage = lazy(() => import('./pages/MyPage/EnterMypage'));
    const NewBreedDiscriminator = lazy(() => import('./pages/NewBreedDiscriminator/NewBreedDiscriminator'));
    const CompareCommonPetIns = lazy(() => import('./pages/CompareCommonPetIns/CompareCommonPetIns'));
    const CompareFierceDogIns = lazy(() => import('./pages/CompareFierceDogIns/CompareFierceDogIns'));
    const CheckMedicalExpenses = lazy(() => import('./pages/CheckMedicalExpenses/CheckMedicalExpenses'));
    const InquiryComplete = lazy(() => import('./pages/Inquiry/inquiryComplete'));
    const AccessRestriction = lazy(() => import('./pages/Recommender/AccessRestriction/AccessRestriction'));
    const PreparingForService = lazy(() => import('./pages/PreparingForService/PreparingForService'));
    const VirtualAccountIssuedComplete = lazy(()=>import('./components/VirtualAccountIssuedComplete'))
    const SignUpInsuranceComplete = lazy(()=>import('./components/SIgnUpInsuranceComplete'))
    const PaymentFail = lazy(()=>import('./components/PaymentFail'))
    const SupportPrediction = lazy(()=>import('./pages/SupportPrediction/SupportPrediction'))

    return (

        <BrowserRouter>
            <Suspense fallback={<Loading/>}>
                <ScrollToTop/>
                <Nav/>
                <Routes>

                    <Route element={<PrivateRoute authenticated={true}/>}>
                        <Route path="/renewinsurance/:id" element={<PetInsurance/>}/>
                        <Route path="/becomerecommender" element={<BecomeRecommender/>}/>
                        <Route path="/applyforrecommender" element={<ApplyForRecommender/>}/>
                        <Route path="/communitywriting" element={<CommunityWriting/>}/>
                        <Route path="/communitywriting/:id" element={<CommunityWriting/>}/>
                        <Route path="/claiminsurance/:id" element={<ClaimInsurance/>}/>
                        <Route path="/fiercedoginsurance/:id" element={<FierceDogInsurance/>}/>
                        <Route path="/fiercedogrenewinsurance/:id" element={<FierceDogInsurance/>}/>
                        <Route path="/petinsurance/:id" element={<PetInsurance/>}/>
                        <Route path="/mypage/:menu" element={<MyPage/>}/>
                        <Route path="/inquiry" element={<Inquiry/>}/>
                        <Route path="/entermypage" element={<EnterMypage/>}/>
                    </Route>

                    <Route path="/aboutus" element={<AboutUs/>}/>
                    <Route path="/whatisrecommender" element={<WhatIsRecommender/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup" element={<SignUp/>}/>
                    <Route path='/premiumcalculator' element={<PremiumCalculator/>}/>
                    <Route path='/findid' element={<FindId/>}/>
                    <Route path='/findpassword' element={<FindPassword/>}/>
                    <Route path="/paymentcase" element={<PaymentCase/>}/>
                    <Route path="/pressrelease" element={<PressRelease/>}/>
                    <Route path="/notice" element={<Notice/>}/>
                    <Route path="/faq" element={<Faq/>}/>
                    <Route path="/notice/:id" element={<NoticePost/>}/>
                    <Route path="/recommendercomplete" element={<RecommenderComplete/>}/>
                    <Route path="/petdictionary" element={<PetDictionary/>}/>
                    <Route path="/petdictionary/:id" element={<Article/>}/>
                    <Route path="/community" element={<Community/>}/>
                    <Route path="/community/:id" element={<CommunityPost/>}/>
                    <Route path="/comment/:id" element={<CommentRoom/>}/>
                    <Route path="/comparecommonpetins" element={<CompareCommonPetIns/>}/>
                    <Route path="/wilddoginsurance" element={<CompareFierceDogIns/>}/>
                    <Route path="/petinsurancecomplete" element={<SignUpInsuranceComplete/>}/>
                    <Route path="/fiercedoginsurancecomplete" element={<SignUpInsuranceComplete/>}/>
                    <Route path="/fiercedogrenewinsurancecomplete" element={<SignUpInsuranceComplete/>}/>
                    <Route path="/renewinsurancecomplete" element={<SignUpInsuranceComplete/>}/>
                    <Route path="/claiminsurancecomplete" element={<ClaimComplete/>}/>
                    <Route path="/signupcomplete" element={<SignUpComplete/>}/>
                    <Route path="/findpasswordcomplete" element={<FindPasswordComplete/>}/>
                    <Route path="/insurancedetail" element={<InsuranceDetail/>}/>
                    <Route path='/kakao/callback' element={<SignHandler/>}/>
                    <Route path='/naver/callback' element={<NaverLoginCallback/>}/>
                    <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
                    <Route path='/termsofservice' element={<TermsOfService/>}/>
                    <Route path='/*' element={<NotFound/>}/>
                    <Route path='/preparingforservice' element={<PreparingForService/>}/>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/checkmedicalexpenses" element={<CheckMedicalExpenses/>}/>
                    <Route path="/inquirycomplete" element={<InquiryComplete/>}/>
                    <Route path="/recommender/accessrestriction/:id" element={<AccessRestriction/>}/>
                    <Route path='/insurancedetail/:id' element={<InsuranceDetail/>}/>
                    <Route path={'/newbreeddiscriminator'} element={<NewBreedDiscriminator/>}/>
                    <Route path={'/lookalikediscriminator'} element={<LookalikeDiscriminator/>}/>
                    <Route path={'/virtualaccountissuedcomplete'} element={<VirtualAccountIssuedComplete/>}/>
                    <Route path={'/signupinsurancefail'} element={<PaymentFail/>}/>
                    <Route path={'/supportprediction/:id'} element={<SupportPrediction/>}/>

                </Routes>

                {isMobile &&
                        <UnderNav/>
                }
                <Footer/>

            </Suspense>
        </BrowserRouter>
    )
        ;
}

export default App;
