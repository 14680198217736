import styled from "styled-components";
import {motion} from "framer-motion";

export const Container = styled.div`
  display: flex;
  align-items: center;

  > a {
    display: block;
    text-decoration: none;
    margin-right: 10.8rem;

    > picture {
      > img {
        width: 16.6rem;
        height: auto;
      }
    }

  }
  
  
  @media screen and (max-width: 1080px) {
    position: static;
  }
`;


export const MenuContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > li {
    margin-right: 3.7rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;


export const KindOfMenu = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;


export const FirstMenu = styled.li`
  color: ${({theme}) => theme.colors.gray7};
  cursor: pointer;


  > a {
    display: block;
    padding:1rem 0;
    color: ${props => props.$active ? ({theme}) => theme.colors.main : ({theme}) => theme.colors.gray7};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    :hover {
      border-radius: 0.5rem;
      color: ${({theme}) => theme.colors.main};
      transition: all 0.3s;
    }
  }
  > button {
    display: block;
    padding:1rem 0;
    color: ${props => props.$active ? ({theme}) => theme.colors.main : ({theme}) => theme.colors.gray7};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    :hover {
      border-radius: 0.5rem;
      color: ${({theme}) => theme.colors.main};
      transition: all 0.3s;
    }
  }
`

export const FirstMenuBox = styled(motion.ul)`
  position: absolute;
  left: -160%;
  top: 100%;
  width: 20rem;
  height: auto !important;
  padding: 1.2rem 0;
  text-align: center;
  border-radius:1.2rem;
  box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
`;

export const SecondMenuBox = styled(FirstMenuBox)`
  left: -160%;

`;

export const ThirdMenuBox = styled(FirstMenuBox)`
  left: -110%;

`;

export const FourthMenuBox = styled(FirstMenuBox)`
  left: -110%;


`;

export const FifthMenuBox = styled(FirstMenuBox)`
  left: -106%;

`;

export const SixthMenuBox = styled(FirstMenuBox)`
  left: -9rem;

`;


export const FirstSubMenu = styled.li`
  cursor: pointer;
  width: 100%;
  color: ${({theme}) => theme.colors.gray7};
z-index: 999;
  > a {
    display: block;
    width: 100%;
    padding: 1.2rem 0;
    color: black;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;

    :hover {
      background-color: ${({theme}) => theme.colors.gray2};
      transition: all 0.3s;
      border-radius: 0;
    }
  }

  > button {
    display: block;
    width: 100%;
    padding: 1.2rem 0;
    color: black;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;

    :hover {
      background-color: ${({theme}) => theme.colors.gray2};
      transition: all 0.3s;
      border-radius: 0;
    }
  }
`

export const SecondMenu = styled(FirstMenu)``
export const ThirdMenu = styled(FirstMenu)``
export const FourthMenu = styled(FirstMenu)``
export const FifthMenu = styled(FirstMenu)``
export const SixthMenu = styled(FirstMenu)``


export const SecondSubMenu = styled(FirstSubMenu)``
export const ThirdSubMenu = styled(FirstSubMenu)``
export const FourthSubMenu = styled(FirstSubMenu)``
export const FifthSubMenu = styled(FirstSubMenu)``
export const SixthSubMenu = styled(FirstSubMenu)``

