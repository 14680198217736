import {atom} from "recoil";
import {recoilPersist} from "recoil-persist";

const {persistAtom} = recoilPersist({
    key:'recoil-persist',
    storage: sessionStorage,
});

export const userState = atom({
    key: 'userState',
    default: null,
    effects_UNSTABLE: [persistAtom],
})


export const tempClaimStep = atom({
    key: 'tempClaimStep',
    default: {}
})

export const signupInfo = atom({
    key:'signupInfo',
    default:{}
})

export const findIdInfo = atom({
    key:'findIdInfo',
    default:{}
})

export const findPwInfo = atom({
    key:'findPwInfo',
    default:{}
})

export const commuFilter = atom({
    key:'commuFilter',
    default:{
        filterId:0,
        align:'최신순'
    }
})

export const recommenderStatus = atom({
    key:'recommenderStatus',
    default:{},
    effects_UNSTABLE: [persistAtom],

})

export const communitySortType = atom({
    key:'commnunitySortType',
    default:'최신순'
})

export const searchInsPetInfo = atom({
    key:'searchInsPetInfo',
    default:{
        petType:'강아지',
        petBreedId:'',
        petBreedName:'',
        petBirth:'',
        coverageCodeList:[],
        petName:'',
        petGender:'',
        startDate:'',
        endDate:'',
    },
    effects_UNSTABLE:[persistAtom]
})

export const myFierceDogInfo = atom({
    key:'myFierceDogInfo',
    default:{
        name:'',
        owner:'',
        birth:'',
        gender:'',
        breedId:'',
        breedName:'',
        regNum:'',
        startDate:'',
        endDate:'',
    },
    effects_UNSTABLE:[persistAtom]

})

export const insuranceIds = atom({
    key:'insuranceIds',
    default:{
        petInsurancePriceId:'',
        tempPriceId:'',
        tempPetId:'',
        petInsuranceId:'',
        tempContractId:'',
    },
    effects_UNSTABLE:[persistAtom]
})

export const userBankData = atom({
    key:'userBankData',
    default:{
        bankName:'',
        bankCode:'',
        accountNum:'',
        transferDate:'',
        receiptId:'',
    }
})

export const recommenderId = atom({
    key:'recommenderId',
    default:'',
    effects_UNSTABLE:[persistAtom]
})

export const insurancePriceData = atom({
    key:'insurancePriceData',
    default:{},
    effects_UNSTABLE:[persistAtom]

})

export const userIdData = atom({
    key:'userIdData',
    default:'',
    effects_UNSTABLE:[persistAtom]

})

export const predictionInput = atom({
    key:'predictionInput',
    default:{
        petName:'',
        petType:'강아지',
        petId:null,
        petBreedId:null,
        petAge:null,
        petBreedName:'',
        petGender:'',
        petBirth: '',
        petNeuter: '',
        petWeight: null,
        prediction:null,
        coverageCodeList:[]
    },
    effects_UNSTABLE:[persistAtom]
})

export const graphData = atom({
    key:'graphData',
    default:[],
    effects_UNSTABLE:[persistAtom]
})

export const diseaseData = atom({
    key:'diseaseData',
    default:[],
    effects_UNSTABLE:[persistAtom]
})

export const recommendInsuranceList = atom({
    key:'recommendInsuranceList',
    default:[],
    effects_UNSTABLE:[persistAtom]
})