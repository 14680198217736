import styled from "styled-components";
import {useEffect, useState} from "react";
import {cookieSetting, requestGet, requestPost} from "../../services/request.js";
import Loading from "../../components/Loading.jsx";
import {useSearchParams} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {userIdData} from "../../recoil/store";
import Modal from "../../components/Modal/Modal";
import {ModalBtn, ModalContent, ModalTitle} from "../../components/Modal/styles/ModalStyles";
import {useNavigate} from "react-router";


const SignHandler = () => {

    const [loading, setLoading] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams()
    const setUserId = useSetRecoilState(userIdData)


    const authorizationCode = searchParams.get('code')

    const navigate = useNavigate()




    const [showModal, setShowModal] = useState(false);

    const handleModal = () => {
        setShowModal(!showModal);
    }


    const getUser = async () => {

        const auth = await requestGet(`/kakao/token?code=${authorizationCode}`);

        if (auth.code === 0) {
            const res = await requestPost(`/kakao/signhandler`, {
                token: auth.data.access_token,
                isAuto: 'n',
            })

            // 일반 회원가입이 되었을 때 이미 가입되었다고 표시
            if (res.code === 1012) {
                setLoading(false);
                handleModal()
            } else if (res.code === 0) {

                await requestPost(`/public/user/loginlog/${res.data.id}`)

                setLoading(false);
                const href = localStorage.getItem("location");
                setUserId(res.data.id)
                cookieSetting('n', res.code)

                if (href === null || typeof href === 'undefined' || href === '' || href === '/') {
                    window.location.replace('/')

                } else {
                    window.location.replace(href)
                }
            } else {
                setLoading(false);
                alert('로그인을 다시 시도해주세요')
            }

        } else {
            setLoading(false);
            alert('로그인을 다시 시도해주세요')
        }


    }

    const prevNavigate = ()=>{

        const href = localStorage.getItem("location");

        if (href === null || typeof href === 'undefined' || href === '' || href === '/') {
            handleModal()

            navigate('/login')

        } else {
            handleModal()

            navigate('/login',{
                state:{
                    pathname:href
                }
            })
        }
    }


    useEffect(() => {
        setLoading(true)
        getUser();

    }, [])


    //여기는 then으로 헀을땐 잘 받아와지는데 그냥 user가지고 토큰받아오려고 하면 promise 뜸 -> 그래서 그냥 const user 지움

    // useEffect(() => {
    //     setLoading(true)
    //
    //     const auth = requestGet(REACT_APP_BASE_URL+`/api/user/kakao/token?code=${authorizationCode}`);
    //
    //     if (auth.code == 0) {
    //         const user = requestGet(REACT_APP_BASE_URL+`/api/user/kakao/signHandler?token=${auth.data.access_token}`,)
    //     }
    //
    //
    // }, []) -> 이걸로하면 promise로 받아오는데 무슨일이고~ / then으로하건 그냥 user 콘솔 찍건 다 promise임


    /*requestGet(REACT_APP_BASE_URL+`/api/user/kakao/token?code=${authorizationCode}`)
        .then((res)=>{


            requestGet(REACT_APP_BASE_URL+`/api/user/kakao/signHandler?token=${res.access_token}`)
                .then((res)=>);      // window.location.href='/'




        }).then( ()=>{
        requestGet(REACT_APP_BASE_URL+`/api/user/kakao/signHandler`,{
            params:{
                token : res.access_token
            }
        })
    })
        .catch((err)=>{
            setLoading(false)
            window.location.href='/login'
        });*/


    return (
        <>
            {showModal &&
                <Modal handleModal={() => {
                    prevNavigate()
                }
                }>
                    <ModalTitle>
                        이미 가입된 회원입니다.
                    </ModalTitle>
                    <ModalContent>
                        로그인을 다시 시도해주세요.
                    </ModalContent>
                    <ModalBtn onClick={() => {
                        prevNavigate()

                    }}>확인</ModalBtn>
                </Modal>
            }
            <Wrapper>
                {loading ? <Loading/> : ''}
            </Wrapper>
        </>
    );
};

export default SignHandler;


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`