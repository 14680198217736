import * as S from './styles/NavStyles';
import Left from "./components/Left";
import Right from "./components/Right";
import {useMediaQuery} from "react-responsive";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {useEffect} from "react";

function Nav() {

    const isMobile = useMediaQuery({query: '(min-width:0) and (max-width:480px)'})
    const location = useLocation();

    // 추천인 코드 파싱
    const parsingQrCode = (pathQrCode) => {
        let userNum = pathQrCode?.slice(6, 16)
        userNum = Number(userNum) - 12345678;

        return userNum;
    }

    // www.petfins.net으로 들어왓을 경우 petfins.net으로 변경
    const redirectWWWUrl = () => {
        const href = window.location.href;

        const wwwUrl = href.split("//")[1].split(".");

        if (wwwUrl[0] == 'www') {
            //window.location.href = 'https://petfins.net' + location.pathname;
            window.location.href = href.replace('www.', '');
        }
    }

    useEffect(() => {
        parsingQrCode(location.pathname)
    }, [location.pathname])


    useEffect(() => {
        redirectWWWUrl();
    }, [window.location.href]);


    return (
        <>
            {isMobile
                ?
                <S.MobileWrapper>
                    <h1>펫핀스</h1>
                    <Link to='/' title={'펫핀스 메인 페이지로 이동'}>
                        <picture>
                            <source
                                srcSet={`https://petfins.cdn.ntruss.com/icons/logo%28width%29.avif`}
                                type={'image/avif'}/>
                            <source
                                srcSet={`https://petfins.cdn.ntruss.com/icons/logo%28width%29.webp`}
                                type={'image/webp'}/>
                            <img
                                src={`https://petfins.cdn.ntruss.com/icons/logo%28width%29.png`}
                                alt={'펫핀스'}
                                width={'118px'}
                                height={'32.5px'}
                                style={{aspectRatio: '3.63 / 1', height: 'auto'}}
                            />
                        </picture>
                    </Link>
                </S.MobileWrapper>
                :
                <S.Wrapper>
                    <h1>펫핀스</h1>
                    <S.Container>
                        <Left/>
                        <Right/>
                    </S.Container>
                </S.Wrapper>
            }

        </>
    );
}

export default Nav;

