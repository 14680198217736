import styled from 'styled-components'
import {ReactComponent as Menu} from "../../svg/menu_underIcon.svg";
import {ReactComponent as PetInsurance} from "../../svg/petinsurance_underIcon.svg";
import {ReactComponent as Home} from "../../svg/home_underIcon.svg";
import {ReactComponent as Claim} from "../../svg/claim_underIcon.svg";
import {ReactComponent as MyPage} from "../../svg/mypage_underIcon.svg";

export const Container = styled.ul`
  width: 100%;
  height: 7.2rem;
  padding: 1rem 3.2rem 2.4rem 3.2rem;
  border-top: 1px solid ${({theme}) => theme.colors.gray2};
  position:fixed;
  bottom:0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 2;

  > li {
    > a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    >button{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  p {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${({theme}) => theme.colors.gray4};
  }
`
export const LinkMenu = styled.li`
  > a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${props => props.activate ? ({theme})=>theme.colors.main : ({theme}) => theme.colors.gray4};
  }
`

export const Icon1 = styled(Menu)`
  width: 2.4rem;
  height: 2.4rem;
`

export const Icon2 = styled(PetInsurance)`
  width: 2.4rem;
  height: 2.4rem;
  
>g{
  >g{
    >path{
      fill:${props => props.activate ? ({theme})=>theme.colors.main : 'auto' };
    }
  }
}
 
`

export const Icon3 = styled(Home)`
  width: 2.4rem;
  height: 2.4rem;
  path{
    fill:${props => props.activate ? ({theme})=>theme.colors.main : 'auto' };
  }

`

export const Icon4 = styled(Claim)`
  width: 2.4rem;
  height: 2.4rem;
  path{
    fill:${props => props.activate ? ({theme})=>theme.colors.main : 'auto' };
  }
`

export const Icon5 = styled(MyPage)`
  width: 2.4rem;
  height: 2.4rem;
  path{
    fill:${props => props.activate ? ({theme})=>theme.colors.main : 'auto' };
  }
  circle{
    fill:${props => props.activate ? ({theme})=>theme.colors.main : 'auto' };
  }
`