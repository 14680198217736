import * as S from '../styles/RightStyles'
import {requestGet} from "../../../services/request";
import {getCookie, removeCookie} from "../../../services/loginCookie";

function Right(props) {

    // 로그아웃 요청 성공하면 웹 스토리지에 저장된 데이터들, 쿠키 다 지우고 메인 페이지로 리다이렉트
    const handleLogout = async () =>{
        const res = await requestGet('/public/user/signout')

        if(res.code === 0){
            sessionStorage.clear()
            localStorage.clear()

            removeCookie('pid_ys')
            window.location.replace('/');
        }
    }

    return (
        <S.Container>
            {getCookie('pid_ys') === 0
            ?
                <>
                    <S.GoToSignUp $active={localStorage.getItem('login')}
                                  to={'/mypage/insuranceadmin'}>
                       내 정보
                    </S.GoToSignUp>
                    <S.LogoutBtn onClick={() => {
                        handleLogout();
                    }}>
                        로그아웃
                    </S.LogoutBtn>
                </>
                :
                <>
                    <S.GoToSignUp $active={localStorage.getItem('login')}
                                  to={'/signup'}>
                        회원가입
                    </S.GoToSignUp>
                    <S.GoToLogin to={'/login'}>
                        로그인
                    </S.GoToLogin>
                </>
            }


        </S.Container>
    );
}

export default Right;